// // //prod
// const API = {
//     url: "https://e-menu.co.edit8.com",
//     port: "2000",
//     serverHostName: "e-menu.co.edit8.com",
//     serverReceivingPort:"2000"
    
// }

 //dev
 const API = {
    url: "https://poevtino.mk",
    port: "2002",
    serverHostName: "poevtino.mk",
     serverReceivingPort: "2002"
    
}
 
module.exports = Object.freeze({
    API: API
});
